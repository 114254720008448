<template>
    <!--max-width="1000px" class="pa-4 grey darken-4 mx-auto"-->
    <div class="flex-grow-1">
        <v-scale-transition>
        <!--<v-container class="pa-4 grey darken-4" v-show="(holderCatalogId || showForm) && isPartner">-->
            <v-row v-show="(holderCatalogId || showForm) && isPartner" class="justify-center ma-0">
                <v-col cols="12" md="10" lg="8" class="pa-0">
                    <v-toolbar dense dark color="blue-grey darken-4" elevation="0">
                        <v-toolbar-title>Информация для каталога</v-toolbar-title>
                    </v-toolbar>

                    <v-sheet elevation="12" class="blue-grey darken-3 pa-4">

                        <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        >
                            <!--no-gutters-->
                            <v-row class="mb-n8">
                                <v-col cols="12" sm="4" class="mb-4">
                                    <v-select
                                            v-model="type"
                                            :items="typeItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Тип"
                                            required
                                            readonly
                                            hide-details
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-4">
                                    <v-select
                                            v-model="status"
                                            :items="statusItems"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Статус"
                                            required
                                            hide-details
                                            outlined
                                            return-object
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" sm="4" class="mb-1">
                                    <v-select
                                        v-model="category"
                                        :items="categoryItems"
                                        item-text="text"
                                        item-value="value"
                                        label="Категории каталога"
                                        :rules="[ v => !!v && v.length > 0 || 'Пустой выбор']"
                                        multiple
                                        outlined
                                        required
                                        hint="Список категорий каталога"
                                        persistent-hint
                                        :menu-props="{ closeOnContentClick: true, bottom: true, offsetY: true}"
                                        clearable
                                    ></v-select>
                                    <!--return-object-->
                                </v-col>

                            </v-row>

                            <v-row class="mb-n7">
                                <v-col cols="12" sm="4" class="mb-n4">
                                    <v-text-field
                                            v-model="routePath"
                                            :rules="routePathRules"
                                            label="Route Path"
                                            required
                                            outlined
                                            :readonly="!!holderCatalogId"
                                            :hint="$store.getters.getWindowProtocol + '//my.bonusarium.ru/partner/' + routePath + '/'"
                                            persistent-hint
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="mb-n4">
                                    <v-text-field
                                            v-model="logoPath"
                                            :rules="routePathRules"
                                            label="Image partial"
                                            required
                                            outlined
                                            :readonly="!!holderCatalogId"
                                            :hint="'Часть имен файлов изображений'"
                                            persistent-hint
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="">
                                    <v-text-field
                                            v-model="breadcrumb"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Breadcrumb"
                                            required
                                            outlined
                                            hint="Имя в строке навигации"
                                            persistent-hint
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n4">
                                <v-col cols="12" sm="6" class="mb-n2">
                                    <v-text-field
                                            class="mb-2"
                                            v-model="siteUrl"
                                            :rules="[urlValidate]"
                                            label="URL сайта"
                                            required
                                            outlined
                                            hint="URL сайта"
                                            persistent-hint
                                    ></v-text-field>
                                    <v-layout class="d-flex flex-column align-center justify-space-between">
                                        <v-img
                                                width="300"
                                                height="150"
                                                :src="bannerPreviewUrl ? bannerPreviewUrl : bannerUrl"
                                                aspect-ratio="1"
                                                contain
                                        ></v-img>
                                        <!--:src="previewUrl ? previewUrl : logoUrl"-->
                                        <!--@click="onUpload"-->
                                        <!--:src="`http://my.bonusarium.ru/img/logo-${logoPath?logoPath:'axioma'}.png`"-->
                                        <!--@change="onFileChange"-->
                                        <v-file-input
                                                v-model="bannerFile"
                                                class="align-self-stretch"
                                                show-size
                                                counter
                                                accept="image/png"
                                                :rules="[bannerFileValidate]"
                                                required
                                                label="Баннер на сайте"
                                                :hint="'Имя файла баннера: banner-' + logoPath + '.png'"
                                                persistent-hint
                                        ></v-file-input>
                                        <!--:rules="[v => !!v || 'Обязательное поле']"-->
                                        <!--, video/avi"-->
                                        <!--truncate-length="10"-->
                                        <!--multiple-->
                                    </v-layout>
                                </v-col>
                                <!--justify-center align-self-center-->
                                <v-col cols="12" sm="6" class="mb-n2">
                                    <v-text-field
                                            class="mb-2"
                                            v-model="siteUrlName"
                                            :rules="[urlValidate]"
                                            label="Имя сайта"
                                            required
                                            outlined
                                            hint="Отображаемое имя сайта"
                                            persistent-hint
                                    ></v-text-field>
                                    <v-layout class="d-flex flex-column align-center justify-space-between">
                                        <v-img
                                            width="150"
                                            height="150"
                                            :src="logoPreviewUrl ? logoPreviewUrl : logoUrl"
                                            aspect-ratio="1"
                                            contain
                                        ></v-img>
                                        <!--:src="previewUrl ? previewUrl : logoUrl"-->
                                        <!--@click="onUpload"-->
                                        <!--:src="`http://my.bonusarium.ru/img/logo-${logoPath?logoPath:'axioma'}.png`"-->
                                        <!--@change="onFileChange"-->
                                        <v-file-input
                                                v-model="logoFile"
                                                class="align-self-stretch"
                                                show-size
                                                counter
                                                accept="image/png"
                                                :rules="[logoFileValidate]"
                                                required
                                                label="Логотип в каталоге"
                                                :hint="'Имя файла лого: logo-' + logoPath + '.png'"
                                                persistent-hint
                                        ></v-file-input>
                                        <!--:rules="[v => !!v || 'Обязательное поле']"-->
                                        <!--, video/avi"-->
                                        <!--truncate-length="10"-->
                                        <!--multiple-->
                                    </v-layout>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n4 mt-4">
                                <v-col cols="12" class="mb-n3">
                                    <!--this.$refs.vTextarea.calculateInputHeight();-->
                                    <!--:key="address"-->
                                    <!--@resize="$refs.addressArea.calculateInputHeight()"-->
                                    <v-textarea
                                            ref="addressArea"
                                            rows="1"
                                            auto-grow
                                            v-model="address"
                                            label="Адрес"
                                            required
                                            outlined
                                            hint="Адрес партнера в каталоге"
                                            persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            
                            <v-row class="mb-n7">
                                <v-col cols="12">
                                    <v-textarea
                                            rows="1"
                                            auto-grow
                                            v-model="htmlDesc"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Html описание партнера"
                                            required
                                            outlined
                                            hint="Наполнение на странице партнера"
                                            persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row class="mb-n7">
                                <v-col cols="12">
                                    <v-textarea
                                            rows="1"
                                            auto-grow
                                            v-model="htmlShortDesc"
                                            :rules="[v => !!v || 'Обязательное поле']"
                                            label="Html информация в каталоге"
                                            required
                                            outlined
                                            hint="Наполнение в общем каталоге"
                                            persistent-hint
                                    ></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="6" class="mb-3">
                                    <v-text-field
                                            v-model="createTime"
                                            label="Создан UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="mb-3">
                                    <v-text-field
                                            v-model="updateTime"
                                            label="Изменен UTC"
                                            outlined
                                            readonly
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                        </v-form>

                        <!--:counter="10"-->
                        <v-text-field v-if="false"
                                      v-model="ref"
                                      :rules="[v => !!v || 'Обязательное поле', refValidate]"
                                      label="ref"
                                      required
                                      outlined
                                      hint="Последняя часть после :: используется как URL партнера в каталоге"
                                      persistent-hint
                                      :readonly="!!holderId"
                        ></v-text-field>

                        <v-card-actions class="pa-0">
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="submit" :disabled="!valid">{{!holderCatalogId ? 'Добавить' : 'Изменить'}}</v-btn>
                            <!--<v-btn color="primary" :disabled="!valid" type="submit">Login</v-btn>-->
                        </v-card-actions>
                    </v-sheet>
                </v-col>
            </v-row>
        <!--</v-container>-->
        </v-scale-transition>
        <v-fab-transition>
            <!--large-->
            <v-btn
                    v-show="!holderCatalogId && !showForm && initialized && isPartner"
                    @click="showForm = !showForm"
                    dark
                    elevation="12"
                    fixed
                    fab
                    bottom
                    right
                    class="mb-16 mr-8 pink">
                <v-icon>add</v-icon>
            </v-btn>
        </v-fab-transition>
    </div>
</template>

<script>
  export default {
    name: "HolderCatalogForm",
    props: ['holder'],

    watch:{
      holder:
        function(v){
          //console.log(v);
          this.initialized = false;
          this.init();
        },

      ref:
        function(value) {
          if (!this.holderCatalogId) {
            let re = new RegExp(/^\w+::\w+::(\w|\.|-)+$/gi);
            //console.log(re.test(value));
            if (re.test(value)) {
              let parts = value.split('::');
              this.logoPath = parts[parts.length - 1];
              this.routePath = parts[parts.length - 1];
            }
          }
        },

      siteUrl: function(v){
        if (!this.holderCatalogId){
          this.siteUrlName = v;
        }
      }
    },

    computed: {
      isPartner: function(){
        //console.log(this.ref);
        let re = new RegExp(/^holder::partner::(\w|\.|-)+$/gi);
        return re.test(this.ref);
      },
      logoUrl: function(){
        return this.$store.getters.getWindowProtocol + '//my.bonusarium.ru/img/logo-' + (this.logoPath ? this.logoPath : 'axioma') + '.png';
        //new Blob(binaryData, {type: "application/zip"});
      },
      bannerUrl: function(){
        return this.$store.getters.getWindowProtocol + '//bonusarium.ru/img/banners/banner-' + (this.logoPath ? this.logoPath : 'axioma') + '.png';
        //new Blob(binaryData, {type: "application/zip"});
      },
      logoPreviewUrl: function(){
        if (this.logoFile)
            return URL.createObjectURL(this.logoFile);
        else
          return null;
      },
      bannerPreviewUrl: function(){
        if (this.bannerFile)
          return URL.createObjectURL(this.bannerFile);
        else
          return null;
      }
    },
    
    data: () => ({
      valid: false,
      showForm: false,
      initialized: false,
      //disabled: true,
      holderId: null,
      holderCatalogId: null,
      routePathRules: [
        //v => !!v || 'Обязательное поле',
        v => /^(\w|-|\.)+$/.test(v) || 'Недопустимые символы'
        // v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      ref: '',
      logoPath: '',
      routePath: '',
      type: {},
      typeItems: [
        {text: 'Партнер', value:'partner'},
        {text: 'Клиент', value:'person'},
      ],
      status: {},
      statusItems: [
        {text: 'Активен', value:'active'},
        {text: 'Отключен', value:'disabled'},
      ],
      breadcrumb: '',
      siteUrl:'',
      siteUrlName:'',
      address: '',
      htmlDesc:'',
      htmlShortDesc:'',
      createTime: '',
      updateTime: '',

      logoFile: null,
      bannerFile: null,
      //previewUrl: null

      categoryItems:[
        {text: 'Общая', value: 'common'},
        {text: 'Еда', value: 'food'},
        {text: 'Товары', value: 'good'},
        {text: 'Услуги', value: 'service'},
        //{text: 'Продукты', value: 'product'},
        {text: 'Авто', value: 'auto'},
        {text: 'Здоровье', value: 'health'},
        {text: 'Красота', value: 'beauty'},
        {text: 'Досуг', value: 'leisure'},
        ],
      category:[]
    }),

    mounted(){
      this.init();
    },

    methods: {
/*
      onFileChange(){
        //console.log(this.logoFile);
        if (this.logoFile){
          this.previewUrl = URL.createObjectURL(this.logoFile);
        }
        else
          this.previewUrl = null;
      },
*/

/*
      onUpload() {
        console.log(this.logoFile);
      },
*/

      init(){
        //console.log(this.refValidate('holder::multinet::100046'));
        if (!this.holder){
          this.holderId = null;
          this.holderCatalogId = null;
          this.initialized = true;
          //this.$emit('update-title', 'Пользователь: Новый');
          //this.$emit('update-title', {title: '<Новый>'});
        }
        else {
          this.holderId = this.holder;
          this.type = this.typeItems[0];
          this.status = this.statusItems[0];
          //потом сделать наоборот, если цифра то гет данные иначе новый
          this.get(this.holderId);
        }
        //console.log(this.holder);
      },

      urlValidate(v){
        return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?$/g.test(v)
          || 'Не валидный URL';
      },

      logoFileValidate(value){
        //console.log(value);
        //console.log((!!this.logoPath && !!this.holderCatalogId) || !!this.logoFile || 'Необходим логотип');
        return (!!this.logoPath && !!this.holderCatalogId) || !!this.logoFile || 'Необходим логотип';
      },

      bannerFileValidate(value){
        //console.log(value);
        //console.log((!!this.logoPath && !!this.holderCatalogId) || !!this.logoFile || 'Необходим логотип');
        return (!!this.logoPath && !!this.holderCatalogId) || !!this.bannerFile || 'Необходим баннер';
      },

      get(id){
        this.$store.dispatch("setLoading", true);
        try{
          this.$store.dispatch("getHolderCatalog", id)
            .then((response) => {
                //console.log(response.data.holderMarket.props.category);
                this.ref = response.data.ref;
                if (response.data.holderRouteId){
    //            if (this.holderCatalogId){
                  this.holderCatalogId = response.data.holderMarket.holderMarketId;
                  this.type = this.typeItems.filter( v => (v.value == response.data.holderMarket.type))[0];
                  this.status = this.statusItems.filter( v => (v.value == response.data.holderMarket.status))[0];
                  this.routePath = response.data.holderMarket.holderRouteId;
                  this.logoPath = response.data.holderMarket.logoFileName;
                  this.breadcrumb = response.data.holderMarket.breadcrumb;
                  this.siteUrl = response.data.holderMarket.siteUrl;
                  this.siteUrlName = response.data.holderMarket.siteUrlName;
                  this.address = response.data.holderMarket.addressStr;
                  this.htmlDesc = response.data.holderMarket.htmlDesc;
                  this.htmlShortDesc = response.data.holderMarket.htmlShortDesc;
                  this.createTime = response.data.holderMarket.createTime;
                  this.updateTime = response.data.holderMarket.updateTime;
                  this.logoFile = null;
                  this.bannerFile = null;
                  this.category = (Array.isArray(response.data.holderMarket.props.category) && response.data.holderMarket.props.category) || [];
                  //надо что-то сделать с logoFile, или инит его через logoRoute или хотя бы показать превью, и сбросить модель
                  this.$nextTick(() => this.resetValidation());
                  //this.$emit('update-holder', {title: this.holderName, holderType: this.holderType.value});
                  //this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId);
                }
            })
            .then(null,(error) => {
                //console.log(error.message.toString());
                this.$store.dispatch("showMessage", {text: 'Ошибка получения каталога: '+ error.message, error: true});
            })
            .finally(() => {
                this.$store.dispatch("setLoading", false);
                this.initialized = true;
                //console.log(this.isPartner, this.ref);
            });
        }
        catch(e){
          this.$store.dispatch("showMessage", {text: e.message, error: true});
          this.$store.dispatch("setLoading", false);
        }
      },

      submit(){
        if (this.validate()){
          //console.log(a);
          this.$store.dispatch("setLoading", true);

          let holderCatalog = new FormData();
          if (this.logoFile){//may be array too
            holderCatalog.append("files[]", this.logoFile, 'logo');
          }
          if (this.bannerFile){
            holderCatalog.append("files[]", this.bannerFile, 'banner');
          }
          holderCatalog.append("id", this.holderId);
          holderCatalog.append("ref", this.ref);
          holderCatalog.append("holderCatalogId",this.holderCatalogId);
          holderCatalog.append("catalogType",this.type.value);
          holderCatalog.append("catalogStatus",this.status.value);
          holderCatalog.append("routePath",this.routePath);
          holderCatalog.append("logoPath",this.logoPath);
          holderCatalog.append("breadcrumb",this.breadcrumb);
          holderCatalog.append("siteUrl",this.siteUrl);
          holderCatalog.append("siteUrlName",this.siteUrlName);
          holderCatalog.append("address",this.address);
          holderCatalog.append("htmlDesc",this.htmlDesc);
          holderCatalog.append("htmlShortDesc",this.htmlShortDesc);
          this.category.map(v => (holderCatalog.append("category[]",v)));

          //console.log(holderCatalog);
          //for (var pair of holderCatalog.entries()) {
            //console.log(pair[0]+ ', ' + pair[1]);
            //if (pair[0] == 'files[]'){
              //console.log(pair[1]);
            //};
          //}

          let method = '';
          if (!this.holderCatalogId){
            method = 'postHolderCatalog';
          }
          else{
            method = 'putHolderCatalog';
          }

          try{
            this.$store.dispatch(method, holderCatalog)
              .then((response) => {
                //console.log(response);
                this.initialized = false;
                this.holderId = response.data.holderId;
                this.get(this.holderId);
/*
                if (method == 'postHolderCatalog'){
                  //this.$store.dispatch("routerPushWrap", {name: "Catalog", params: {holderId: '' + response.data.holderId}});//'/holder/' + response.data.holderId);
                  //this.$store.dispatch("routerPushWrap", {path:'/holder/' + response.data.holderId});
                  this.$store.dispatch("routerPushWrap", '/holder/' + response.data.holderId + '/catalog');
                  //this.partners = response.data.data.map((i) => ({id: i.id, name: i.shortName, holderRouteId: i.holderRouteId}));
                }
                else if (method == 'putHolderCatalog'){
                  //this.get(this.holderId);
                }
*/
              })
              .then(null,(error) => {
                console.log(error);
                //this.$store.dispatch("showMessage", {text: 'Ошибка получения данных', error: true});
              })
              .finally(() => {
                this.$store.dispatch("setLoading", false);
              });
          }
          catch(e){
            this.$store.dispatch("showMessage", {text: e.message, error: true});
            this.$store.dispatch("setLoading", false);
          }
        }
      },

      validate(){
        //console.log();
        return this.$refs.form.validate();
      },

      reset(){
        this.$refs.form.reset();
      },

      resetValidation(){
        if (this.$refs['form']){
          this.$refs.form.resetValidation();
        }
      },
    },
  };
</script>

<style scoped>
</style>